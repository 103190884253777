document.addEventListener("DOMContentLoaded", function(){
    const searchTrigger = document.querySelector('.search-trigger');
    const searchForm = document.querySelector('.search-form');
    const searchTriggerIcon = document.querySelector('.search-trigger i.far');
    const searchSubmit = document.querySelector('.search-submit');
    const searchGlass = document.querySelector('.glass');
    const xMark = document.querySelector('.xmark');
    const searchInput = document.querySelector('input.search');
    const menuItems = document.querySelectorAll('.menu .menu-item:not(.search) a');

    searchTrigger.addEventListener('click', (event) => {
        // prevent default action
        event.preventDefault();
        toggleSearch();
     });
  
     searchSubmit.addEventListener('click', () => {
        searchForm.submit();
     });


    // Show & Hide Toggle Menu Function
    function toggleSearch() {
        searchTrigger.classList.toggle('open');
        searchForm.classList.toggle('open');

        // searchTriggerIcon.classList.toggle('fa-times')
        // searchTriggerIcon.classList.toggle('fa-search')

        searchGlass.classList.toggle('hidden');
        xMark.classList.toggle('hidden');

        // toggle all the menu items
        menuItems.forEach((item) => {
            item.classList.toggle('clear-color');
        });

        searchInput.focus();
    }

});